import React, { FC } from "react";
import "./Typography.css";

import TypographyMaterial from "@material-ui/core/Typography";

type TypographyProps = { type: any; value?: string; style?: any };

const Typography: FC<TypographyProps> = ({
  type = "h1",
  value = "",
  style = {},
  ...other
}) => {
  return (
    <TypographyMaterial variant={type} style={{ ...style }} {...other}>
      {value}
    </TypographyMaterial>
  );
};

export default Typography;
