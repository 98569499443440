import React, { FC, useEffect } from "react";
import "./FullscreenContainer.css";
import { debounce } from "../Utils";
import clsx from "clsx";

const DEBOUNCE_TIME = 200;

// We listen to the resize event
window.addEventListener(
  "resize",
  debounce(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, DEBOUNCE_TIME)
);

type FullscreenContainerProps = { className?: string };
const FullscreenContainer: FC<FullscreenContainerProps> = ({
  children,
  className,
}) => {
  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  return (
    <div className={clsx("FullscreenContainer", className)}>{children}</div>
  );
};

export default FullscreenContainer;
