import axios from "axios";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/firebase-storage";
import "firebase/analytics";
import "firebase/database";
import CONFIG from "./config";

const config = {
  apiKey: "AIzaSyA7IzZ5KnFBg-CKKD8sv3oOnINtQccdIDs",
  authDomain: "scaffinspect.firebaseapp.com",
  projectId: "scaffinspect",
  storageBucket: "scaffinspect.appspot.com",
  messagingSenderId: "948109493993",
  appId: "1:948109493993:web:65fb3d26b4c92fa6545278",
  measurementId: "G-QEM60TR55F",
};

if (!app.apps.length) {
  app.initializeApp(config);
}

/** Firebase services */
const auth = app.auth();
const db = app.firestore();
// const storage = app.storage();
// const analytics = app.analytics();

const addUser = (data: any) => {
  return db.collection("users").add(data);
};

const editUser = (user_data: any) => {
  const { id, ...rest } = user_data;
  return db.collection("users").doc(id).set(rest);
};

const loginUser = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email, password);
};

const logoutUser = () => {
  return auth.signOut();
};

const resetPassword = (email: string) => {
  return auth.sendPasswordResetEmail(email);
};

const getUser = () => {
  return auth.currentUser;
};

const getUserData = () => {
  const user = getUser();
  if (!user) return null;

  return db.collection("users").where("email", "==", user.email).get();
};

const getUserDataByEmail = (email = null) => {
  if (email === null) return null;

  return db.collection("users").where("email", "==", email).get();
};

const getUserIdToken = () => {
  const user = getUser();

  if (!user) return null;

  return user.getIdTokenResult();
};

const getUsers = () => {
  return db.collection("users").get();
};

const addUserDocListener = (callback = (data: any) => {}) => {
  const user = getUser();

  if (!user) return null;

  return db
    .collection("users")
    .where("email", "==", user.email)
    .onSnapshot((userSnap) => {
      userSnap.forEach((userDoc) => {
        callback(userDoc.data());
      });
    });
};

const authStateChanged = (callback?: any) => {
  auth.onAuthStateChanged(callback);
};

const getIdToken = () => {
  return auth.currentUser?.getIdToken(true);
};

const updateUserClaims = (claims: any = {}) => {
  return getIdToken()?.then((token) => {
    return axios.post(
      CONFIG.FIREBASE,
      {
        claims,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  });
};

export {
  app,
  addUser,
  editUser,
  loginUser,
  logoutUser,
  resetPassword,
  getUser,
  getUserData,
  getUserDataByEmail,
  getUserIdToken,
  getUsers,
  addUserDocListener,
  authStateChanged,
  updateUserClaims,
  getIdToken,
};
