import React, { FC, Fragment } from "react";
import Button from "../../components/Button";
import Inputfield from "../../components/Inputfield";

type InputContainerProps = {
  email?: string;
  emailError?: any;
  handleOnClickLogin?: any;
  handleOnChangeEmail?: any;
  handleOnChangePassword?: any;
  isErrorEmail?: any;
  password?: string;
};
const InputContainer: FC<InputContainerProps> = ({
  email,
  emailError,
  handleOnClickLogin,
  handleOnChangeEmail,
  handleOnChangePassword,
  isErrorEmail,
  password,
}) => {
  return (
    <div className="InputContainer-LoginScreen">
      <Fragment>
        <Inputfield
          style={{ marginBottom: 24 }}
          placeholder="E-mailadress"
          type="email"
          onChange={handleOnChangeEmail}
          error={isErrorEmail}
          helperText={isErrorEmail ? emailError : ""}
          value={email}
        />
        <Inputfield
          style={{ marginBottom: 24 }}
          placeholder="Lösenord"
          type="password"
          onChange={handleOnChangePassword}
          value={password}
        />
      </Fragment>
      <div
        className="ButtonContainer-LoginScreen"
        style={{
          marginBottom: 24,
          justifyContent: "center",
        }}
      >
        <Button
          style={{ minWidth: "45%" }}
          title="Logga in"
          type="primary"
          onClick={handleOnClickLogin}
          disabled={
            isErrorEmail || email?.length === 0 || password?.length === 0
          }
        />
      </div>
    </div>
  );
};

export default InputContainer;
