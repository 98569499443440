import React, { FC } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SelectMaterial from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

import "./Select.css";

type SelectProps = {
  id?: any;
  placeholder?: string;
  onChange?: any;
  value?: any;
  style?: any;
  options?: any;
  icon?: any;
  required?: any;
  disabled?: any;
};
const Select: FC<SelectProps> = ({
  id,
  placeholder = "",
  onChange = () => {},
  value,
  style,
  icon,
  options = [{ value: 0, label: "" }],
  required,
  disabled,
}) => {
  let getNonIconField = () => (
    <div className="SelectContainer" style={style}>
      <FormControl
        variant="filled"
        fullWidth
        required={required}
        disabled={disabled}
      >
        <InputLabel>{placeholder}</InputLabel>
        <SelectMaterial
          onChange={onChange}
          value={value}
          disableUnderline
          id={id ? id : ""}
        >
          {options.map((val: any, idx: number) => {
            return (
              <MenuItem key={idx} value={val.value}>
                {val.label}
              </MenuItem>
            );
          })}
        </SelectMaterial>
      </FormControl>
    </div>
  );

  let getIconField = () => (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={1}
      style={style}
    >
      <Grid item>{icon}</Grid>
      <Grid item style={{ flex: 1 }}>
        <div className="SelectContainer" style={style}>
          <FormControl variant="filled" fullWidth disabled={disabled}>
            <InputLabel>{placeholder}</InputLabel>
            <SelectMaterial
              onChange={onChange}
              value={value}
              disableUnderline
              id={id ? id : ""}
            >
              {options.map((val: any, idx: number) => {
                return (
                  <MenuItem key={idx} value={val.value}>
                    {val.label}
                  </MenuItem>
                );
              })}
            </SelectMaterial>
          </FormControl>
        </div>
      </Grid>
    </Grid>
  );

  return icon ? getIconField() : getNonIconField();
};

export default Select;
