/** Debounce functionality */
function debounce(fn: () => any, ms: number) {
  let timer: any;
  return (_: any) => {
    clearTimeout(timer);
    timer = setTimeout((_: any) => {
      timer = null;
      fn();
    }, ms);
  };
}

export { debounce };
