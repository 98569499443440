import React, { FC } from "react";
import "./Inputfield.css";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

type InputfieldProps = {
  id?: string;
  placeholder?: string;
  onChange?: any;
  disabled?: boolean;
  value?: string;
  style?: any;
  icon?: any;
  type?: any;
  error?: any;
  helperText?: any;
  required?: any;
};
const Inputfield: FC<InputfieldProps> = ({
  id,
  placeholder,
  onChange = () => {},
  disabled = false,
  value,
  style,
  icon,
  type,
  error,
  helperText,
  required,
}) => {
  let getNonIconField = () => (
    <TextField
      id={id}
      label={placeholder}
      onChange={onChange}
      disabled={disabled}
      value={value}
      fullWidth
      type={type}
      size="small"
      InputProps={{
        disableUnderline: true,
        style: { color: "white", borderRadius: 32 },
      }}
      InputLabelProps={{
        style: {
          color: "#798895",
          height: 5,
        },
      }}
      variant="filled"
      style={style}
      error={error}
      helperText={helperText}
      required={required}
    />
  );

  let getIconField = () => (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={1}
      style={style}
    >
      <Grid item>{icon}</Grid>
      <Grid item style={{ flex: 1 }}>
        <TextField
          id={id}
          label={placeholder}
          onChange={onChange}
          disabled={disabled}
          value={value}
          fullWidth
          type={type}
          size="small"
          InputProps={{
            disableUnderline: true,
            style: { color: "white", borderRadius: 32 },
          }}
          InputLabelProps={{
            style: {
              color: "#798895",
              height: 5,
            },
          }}
          variant="filled"
          error={error}
          helperText={helperText}
          required={required}
        />
      </Grid>
    </Grid>
  );

  return icon ? getIconField() : getNonIconField();
};

export default Inputfield;
