import React, { FC, useState, useEffect } from "react";
import "./Snackbar.css";
import SnackbarMaterial from "@material-ui/core/Snackbar";
import Alert, { AlertProps } from "@material-ui/lab/Alert";

interface SnackbarProps extends AlertProps {
  text?: string;
  isOpen?: boolean;
  severity?: any;
  autoHideDuration?: number;
}
const Snackbar: FC<SnackbarProps> = ({
  text,
  variant = "outlined",
  severity = "info",
  isOpen,
  autoHideDuration,
  ...other
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) setOpen(isOpen);
  }, [isOpen]);

  return (
    <SnackbarMaterial
      open={open}
      autoHideDuration={autoHideDuration || 5000}
      onClose={handleClose}
      {...other}
    >
      <Alert onClose={handleClose} severity={severity} variant={variant}>
        {text}
      </Alert>
    </SnackbarMaterial>
  );
};

export default Snackbar;
