import React, { FC } from "react";
import "./Table.css";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TableMaterial from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// import Collapse from "@material-ui/core/Collapse";
// import AssignmentIcon from "@material-ui/icons/Assignment";

type TableProps = {
  rows: Array<any>;
  headers?: Array<any>;
  onDoubleClick?: any;
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    cursor: "pointer",
  },
  icon: {
    color: "#7c8b98",
  },
  iconArrow: {
    color: "white",
  },
});

function Row(props: { row: any; onDoubleClick?: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        hover
        onDoubleClick={(e: any) => props.onDoubleClick(e, row)}
      >
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {null}
          </IconButton>
        </TableCell>
        <TableCell style={{ wordBreak: "break-word", userSelect: "none" }}>
          {row.name}
        </TableCell>
        <TableCell style={{ wordBreak: "break-word", userSelect: "none" }}>
          {row.email}
        </TableCell>
        <TableCell style={{ wordBreak: "break-word", userSelect: "none" }}>
          {row.company}
        </TableCell>
        <TableCell style={{ wordBreak: "break-word", userSelect: "none" }}>
          {row.role}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Table: FC<TableProps> = ({ rows, headers = [], onDoubleClick }) => {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <Paper elevation={0}>
      <TableContainer>
        <TableMaterial>
          <TableHead>
            <TableRow>
              <TableCell />
              {headers.map((head, idx) => (
                <TableCell key={idx} style={{ userSelect: "none" }}>
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <Row key={idx} row={row} onDoubleClick={onDoubleClick} />
            ))}
          </TableBody>
        </TableMaterial>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={"Antal rader"}
        backIconButtonProps={{ style: { color: "white" } }}
        nextIconButtonProps={{ style: { color: "white" } }}
      /> */}
    </Paper>
  );
};

export default Table;
