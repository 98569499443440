import React, { FC } from "react";
import { Route, Redirect } from "react-router-dom";
// import { getUserIdToken } from "../../services/firebase";

type ProtectedRouteProps = {
  component?: any;
  user: any;
  rest?: any;
  exact?: any;
  path?: string;
};
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component,
  user,
  exact,
  path,
  ...rest
}) => {
  // const [claims, setClaims] = useState({ admin: false });

  // useEffect(() => {
  //   getUserIdToken()?.then((token: any) => {
  //     setClaims(token.claims);
  //   });
  // }, []);

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(props) => {
        if (!user)
          <Redirect
            to={{
              pathname: "/",
            }}
          />;

        /** CHECK FOR CLAIMS HERE SOMEHOW */
        return component;
      }}
    />
  );
};

export default ProtectedRoute;
