import React, { FC, useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  icon: {
    marginLeft: 2,
    borderRadius: 32,
    width: 32,
    height: 32,
    backgroundColor: "#798895",
  },
  checkedIcon: {
    backgroundColor: "#00970e",
    "&:before": {
      display: "block",
      width: 32,
      height: 32,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
});

// Inspired by blueprintjs
function StyledCheckbox(props: CheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

type OptionProps = {
  id?: any;
  idx: any;
  label: string;
  checked: boolean;
};

type CheckboxListProps = {
  options: Array<OptionProps>;
  value?: any;
  handleOnChangeCheckbox?: any;
  style?: any;
};

const CheckboxList: FC<CheckboxListProps> = (props) => {
  var [options, setOptions] = useState<any>([]);
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const handleOnChange = (idx: any) => {
    const optionsCopy = options.map((x: any) =>
      x.idx === idx
        ? {
            idx: x.idx,
            label: x.label,
            id: x.id ? x.id : "",
            checked: !x.checked,
          }
        : x
    );
    setOptions(optionsCopy);
    props.handleOnChangeCheckbox(optionsCopy);
  };

  return (
    <Fragment>
      {options.map((el: any) => (
        <FormControlLabel
          id={el.id ? el.id : ""}
          key={el.idx}
          control={
            <StyledCheckbox
              checked={el.checked}
              onChange={() => {
                handleOnChange(el.idx);
              }}
            />
          }
          label={el.label}
          style={{ width: "100%", userSelect: "none", ...props.style }}
        />
      ))}
    </Fragment>
  );
};

export default CheckboxList;
