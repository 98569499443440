import React from "react";
import ThemeManager from "./components/ThemeManager";

import RouteManager from "./components/RouteManager";

import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";

function App() {
  return (
    <ThemeManager>
      <Router>
        <RouteManager />
      </Router>
    </ThemeManager>
  );
}

export default App;
