import React, { FC, useEffect } from "react";
import Typography from "../Typography";
import Button from "../Button";
import DrawerMaterial from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import "./Drawer.css";

type DrawerProps = {
  user?: string;
  company?: string;
  onClickShowProfile?: any;
  onCloseDrawer?: any;
  open?: boolean;
  options?: Array<any>;
};
const Drawer: FC<DrawerProps> = ({
  user = "",
  company = "",
  onClickShowProfile = () => {},
  onCloseDrawer = () => {},
  open = false,
  options = [{ value: "", icon: null }],
}) => {
  const [openState, setOpenState] = React.useState(false);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  return (
    <React.Fragment>
      <DrawerMaterial anchor={"left"} open={openState}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <AccountCircleIcon
              fontSize="inherit"
              style={{ paddingRight: 8, fontSize: 50, fill: "#4d4b51" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                type="h6"
                value={`${user ? user : "Anonym"}, ${
                  company ? company : "Anonym"
                }`}
                style={{ color: "#4d4b51", userSelect: "none" }}
              />
              <Button
                title="Visa profil"
                type="text"
                onClick={onClickShowProfile}
                style={{
                  color: "#4d4b51",
                  userSelect: "none",
                  border: "none",
                  textTransform: "none",
                  textDecoration: "none",
                  display: "inline-block",
                }}
              />
            </div>
            <ChevronLeftIcon
              fontSize="inherit"
              style={{
                paddingLeft: 8,
                fontSize: 32,
                fill: "#4d4b51",
                cursor: "pointer",
              }}
              onClick={() => {
                onCloseDrawer();
                setOpenState(false);
              }}
            />
          </div>
          <Divider />
          <List>
            {options.map((val: any, idx: number) => (
              <ListItem button key={idx} onClick={val.onClick}>
                <ListItemIcon>{val.icon}</ListItemIcon>
                <ListItemText primary={val.value} />
              </ListItem>
            ))}
          </List>
        </div>
      </DrawerMaterial>
    </React.Fragment>
  );
};

export default Drawer;
