import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import "./Button.css";

const useStyles = makeStyles(() => ({
  primary: {
    borderWidth: 0,
    color: "white",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    borderRadius: 32,
    backgroundColor: "#00970d",
    "&:disabled": {
      color: "#7c8b98",
    },
  },
  secondary: {
    color: "#b9bcc0",
    fontFamily: "Roboto",
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderWidth: 1,
    borderColor: "rgb(112, 112, 112)",
    borderStyle: "solid",
    margin: -1,
    textTransform: "capitalize",
    borderRadius: 32,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    "&:disabled": {
      color: "#7c8b98",
    },
  },
  text: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    textDecoration: "underline",
    boxShadow: "none",
    borderWidth: 1,
    borderRadius: 0,
    borderColor: "rgba(121, 136, 149, 0.5)",
    borderStyle: "none none solid none",
    color: "#798895",
    display: "inline-block",
    fontFamily: "Roboto",
    padding: 0,
    minWidth: 0,
    minHeight: 0,
    margin: 0,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)",
      boxShadow: "none",
    },
    "&:disabled": {
      color: "#7c8b98",
    },
  },
}));

type ButtonProps = {
  title?: string;
  type?: "primary" | "secondary" | "text" | undefined;
  onClick?: any;
  style?: any;
  disabled?: any;
};

const ButtonCustom: FC<ButtonProps> = ({
  title,
  type,
  onClick,
  disabled,
  style,
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      className={type ? classes[type] : classes.primary}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default ButtonCustom;
