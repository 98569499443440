import React, { FC, useState } from "react";
import Typography from "../../components/Typography";
import InputContainer from "./InputContainer";
import FullscreenContainer from "../../components/FullscreenContainer";
import Snackbar from "../../components/Snackbar";
import Loading from "../../components/Loading";
import validator from "validator";
import { loginUser } from "../../services/firebase";
import { ReactComponent as ScaffinspectLogo } from "../../assets/logos/Scaffinspect-logo.svg";
import { useHistory } from "react-router-dom";

import "./LoginScreen.css";

type LoginScreenProps = {};
const LoginScreen: FC<LoginScreenProps> = () => {
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isErrorEMail, setIsErrorEMail] = useState(false);
  const [emailError] = useState<string>("Felaktig e-mailadress");

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  return (
    <FullscreenContainer className="LoginScreen-FullscreenContainer">
      <Loading isOpen={isLoading} />
      <Snackbar
        text={snackbarMessage}
        severity="error"
        isOpen={isSnackbarOpen}
      />
      <div className="SubContainer-LoginScreen">
        <div className="LogoContainer-LoginScreen">
          <ScaffinspectLogo style={{ width: "40%", maxWidth: 400 }} />
          <Typography
            type="h1"
            value="Scaffinspect"
            style={{ userSelect: "none" }}
          />
          <Typography type="h3" value="Admin" style={{ userSelect: "none" }} />
        </div>
        <InputContainer
          email={email}
          emailError={emailError}
          handleOnClickLogin={() => {
            setIsLoading(true);
            loginUser(email, password)
              .then((userCred) => {
                setIsLoading(false);
                history.push("/users");
              })
              .catch((err) => {
                setIsLoading(false);
                setIsSnackbarOpen(true);
                setSnackbarMessage(err.message);
              });
          }}
          handleOnChangeEmail={(e?: any) => {
            let { value } = e.target;
            setEmail(value);

            value.length === 0
              ? setIsErrorEMail(false)
              : setIsErrorEMail(!validator.isEmail(value));
          }}
          handleOnChangePassword={(e?: any) => {
            setPassword(e.target.value);
          }}
          isErrorEmail={isErrorEMail}
          password={password}
        />
      </div>
    </FullscreenContainer>
  );
};

export default LoginScreen;
