import React, { FC } from "react";
import "./Dialog.css";

import DialogMaterial from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

type DialogProps = {
  open: boolean;
  content?: any;
  action?: any;
  title?: string;
  handleOnClickCancel?: any;
};

const Dialog: FC<DialogProps> = ({
  open = false,
  content = null,
  action = null,
  title = "",
  handleOnClickCancel = () => {},
}) => {
  return (
    <DialogMaterial open={open} onClose={handleOnClickCancel}>
      <DialogTitle style={{ userSelect: "none" }}>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{action}</DialogActions>
    </DialogMaterial>
  );
};

export default Dialog;
