import React, { FC, useEffect, useState } from "react";

import UsersScreen from "../../screens/UsersScreen";
import LoginScreen from "../../screens/LoginScreen";
import ProfileScreen from "../../screens/ProfileScreen";
// import ProjectScreen from "../../screens/ProjectScreen";
import ProtectedRoute from "../../components/ProtectedRoute";

import { authStateChanged } from "../../services/firebase";
import { useHistory, Switch, Route } from "react-router-dom";

type RouteManagerProps = {};
const RouteManager: FC<RouteManagerProps> = () => {
  let history = useHistory();

  const [user, setUser] = useState(null);

  useEffect(() => {
    authStateChanged((user?: any) => {
      setUser(user);

      if (!user) {
        history.push("/");
      } else {
        history.push("/users");
      }
    });
  }, [history]);

  return (
    <Switch>
      <Route exact path="/">
        <LoginScreen />
      </Route>
      <ProtectedRoute
        exact={true}
        path="/profile"
        user={user}
        component={<ProfileScreen />}
      />
      {/* <ProtectedRoute
        exact={true}
        path="/projects"
        user={user}
        component={<ProjectScreen  />}
      /> */}
      <ProtectedRoute
        exact={true}
        path="/users"
        user={user}
        component={<UsersScreen />}
      />
    </Switch>
  );
};

export default RouteManager;
