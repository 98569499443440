import React, { FC, useState, useEffect } from "react";
import FullscreenContainer from "../../components/FullscreenContainer";
import NavBarContainer from "../../components/NavBarContainer";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Dialog from "../../components/Dialog";
import Inputfield from "../../components/Inputfield";
import Select from "../../components/Select";
import CheckboxList from "../../components/CheckboxList";

import AddIcon from "@material-ui/icons/Add";
import Drawer from "../../components/Drawer";
import Typography from "../../components/Typography";
import PeopleIcon from "@material-ui/icons/People";
// import AssignmentIcon from "@material-ui/icons/Assignment";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import {
  getUsers,
  addUser,
  editUser,
  updateUserClaims,
} from "../../services/firebase";
import "./UsersScreen.css";

type UsersScreenProps = {};
const UsersScreen: FC<UsersScreenProps> = (props) => {
  let history = useHistory();

  const CLAIMS_INIT = { admin: false, store_pdf: true, store_form: true };

  const USER_INIT = {
    email: "",
    name: "",
    phone: "",
    role: "",
    amv: false,
  };
  const CHECKBOXLIST_INIT = [
    {
      id: "amv",
      idx: 0,
      label: "Arbetsmiljöverket",
      checked: false,
    },
  ];

  const options = [
    {
      value: (
        <Typography
          type="body1"
          value="Användare"
          style={{ color: "#4d4b51", userSelect: "none" }}
        />
      ),
      icon: <PeopleIcon style={{ fill: "#4d4b51" }} />,
      onClick: () => {
        setOpen(false);
        history.push("/users");
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState<string>("add");
  const [users, setUsers] = useState<any>([]);
  const [user, setUser] = useState<any>(USER_INIT);
  const [claims, setClaims] = useState<any>(CLAIMS_INIT);
  const [isOkToAddUser, setIsOkToAddUser] = useState(false);
  const [checkboxOptions, setCheckboxOptions] = useState(CHECKBOXLIST_INIT);

  useEffect(() => {
    setLoading(true);
    getUsers()
      .then((usersDoc: any) => {
        const users: any = [];
        usersDoc.forEach((userDoc: any) => {
          users.push({ id: userDoc.id, ...userDoc.data() });
        });

        setUsers(users);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    const isOk =
      user.name.length > 0 &&
      user.email.length > 0 &&
      user.phone.length > 0 &&
      user.role.length > 0;
    setIsOkToAddUser(isOk);
  }, [user]);

  const handleOnClickAdd = () => {
    setUser(USER_INIT);
    setClaims(CLAIMS_INIT);
    setCheckboxOptions(CHECKBOXLIST_INIT);
    setDialogMode("add");
    setOpenDialog(true);
  };

  const handleOnChangeInputfield = (e?: any) => {
    const { id, value } = e.target;

    setUser((prevState: any) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleOnChangeSelect = (e?: any, opt?: any) => {
    let { value } = e.target;

    setUser((prevState: any) => ({
      ...prevState,
      [opt]: value,
    }));

    if (opt === "role") {
      setClaims((prevState: any) => ({
        ...prevState,
        admin: value === "admin" ? true : false,
      }));
    }
  };

  const handleOnChangeCheckbox = (optionsCopy?: any) => {
    let { checked } = optionsCopy[0]; // Arbetsmiljöverket

    setCheckboxOptions(optionsCopy);

    setUser((prevState: any) => ({
      ...prevState,
      amv: checked,
    }));

    setClaims((prevState: any) => ({
      ...prevState,
      store_pdf: !checked,
      store_form: !checked,
    }));
  };

  const handleOnDoubleClickRow = (e: any, row: any) => {
    setDialogMode("edit");
    setUser(row);
    refreshClaimsFromRow(row);

    const updatedCheckBoxOptions = checkboxOptions.map((x) => {
      if (x.id === "amv") {
        return {
          ...x,
          checked: row.amv,
        };
      } else {
        return x;
      }
    });

    setCheckboxOptions(updatedCheckBoxOptions);
    setOpenDialog(true);
  };

  const refreshClaimsFromRow = (row: any) => {
    setClaims((prevState: any) => ({
      ...prevState,
      admin: row.role === "admin" ? true : false,
      store_pdf: row.amv ? false : true,
      store_form: row.amv ? false : true,
    }));

    return;
  };

  return (
    <FullscreenContainer className="UsersScreen-FullscreenContainer">
      <Loading isOpen={loading} />
      <Dialog
        open={openDialog}
        title="Lägg till ny användare"
        content={
          <div>
            <Inputfield
              id="name"
              placeholder="Fullständgt namn"
              style={{ marginTop: 16 }}
              onChange={handleOnChangeInputfield}
              value={user.name}
            />
            <Inputfield
              id="email"
              placeholder="E-mailadress"
              style={{ marginTop: 16 }}
              onChange={handleOnChangeInputfield}
              value={user.email}
            />
            <Inputfield
              id="company"
              placeholder="Företag"
              style={{ marginTop: 16 }}
              onChange={handleOnChangeInputfield}
              value={user.company}
            />
            <Inputfield
              id="phone"
              placeholder="Telefon"
              style={{ marginTop: 16 }}
              onChange={handleOnChangeInputfield}
              value={user.phone}
            />
            <Select
              placeholder="Roll"
              options={[
                { label: "Inspektör", value: "inspector" },
                { label: "Admin", value: "admin" },
              ]}
              style={{ marginTop: 16 }}
              onChange={(e: any) => {
                handleOnChangeSelect(e, "role");
              }}
              value={user.role}
            />
            <CheckboxList
              options={checkboxOptions}
              style={{ color: "red" }}
              handleOnChangeCheckbox={handleOnChangeCheckbox}
            />
          </div>
        }
        action={
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <Button
              style={{ minWidth: "45%" }}
              type="secondary"
              title="Avbryt"
              onClick={() => {
                setOpenDialog(false);
              }}
            />
            <Button
              style={{ minWidth: "45%" }}
              title={
                dialogMode === "add"
                  ? "Lägg till"
                  : dialogMode === "edit"
                  ? "Uppdatera"
                  : ""
              }
              disabled={!isOkToAddUser}
              onClick={() => {
                if (dialogMode === "add") {
                  setOpenDialog(false);
                  setLoading(true);
                  addUser({ claims, ...user })
                    .then(() => {
                      setLoading(false);
                    })
                    .catch((err) => {
                      console.log(err);
                      setLoading(false);
                    });
                } else if (dialogMode === "edit") {
                  setOpenDialog(false);
                  setLoading(true);
                  editUser(user)
                    ?.then(() => {
                      updateUserClaims(claims)?.then(() => {
                        setLoading(false);
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                      setLoading(false);
                    });
                }
              }}
            />
          </div>
        }
      />
      <div className="PaddingContainer">
        <NavBarContainer
          onClickMenu={() => setOpen(true)}
          header="Användare"
          endContent={
            <AddIcon
              style={{ fill: "white", cursor: "pointer" }}
              onClick={handleOnClickAdd}
            />
          }
        />
        <Drawer
          user={user.firstName}
          company={user.company}
          options={options}
          open={open}
          onCloseDrawer={() => setOpen(false)}
          onClickShowProfile={() => {
            history.push("/profile");
          }}
        />
      </div>
      <div className="UsersScreen-Table">
        <Table
          headers={["name", "email", "company", "role"]}
          rows={users}
          onDoubleClick={handleOnDoubleClickRow}
        />
      </div>
    </FullscreenContainer>
  );
};

export default UsersScreen;
