import React, { FC, Fragment } from "react";
import "./NavBarContainer.css";

import Typography from "../Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

type NavBarContainerProps = {
  header?: string;
  onClickMenu?: any;
  endContent?: any;
};
const NavBarContainer: FC<NavBarContainerProps> = ({
  header = "X. Header",
  onClickMenu,
  children,
  endContent,
}) => {
  return (
    <Fragment>
      <div className="TopContainer-NavBarContainer">
        <div>
          <FontAwesomeIcon
            icon={faBars}
            size={"2x"}
            color={"white"}
            onClick={onClickMenu}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div id="Text">
          <Typography type="h4" value={header} />
        </div>
        <div id="EndContent">{endContent}</div>
      </div>
      {children}
    </Fragment>
  );
};

export default NavBarContainer;
