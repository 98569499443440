import React, { FC, useState, useEffect } from "react";
import EmailIcon from "@material-ui/icons/Email";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import CallIcon from "@material-ui/icons/Call";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LanguageIcon from "@material-ui/icons/Language";

import PeopleIcon from "@material-ui/icons/People";
// import AssignmentIcon from "@material-ui/icons/Assignment";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";

import FullscreenContainer from "../../components/FullscreenContainer";
import NavBarContainer from "../../components/NavBarContainer";
import Inputfield from "../../components/Inputfield";
import Select from "../../components/Select";
import Divider from "../../components/Divider";
import Module from "../../components/Module";
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import Typography from "../../components/Typography";
import { logoutUser, getUserData } from "../../services/firebase";

import "./ProfileScreen.css";

type ProfileScreenProps = { onClickLogOut?: any };
const ProfileScreen: FC<ProfileScreenProps> = ({ onClickLogOut }) => {
  let history = useHistory();

  const options = [
    {
      value: (
        <Typography
          type="body1"
          value="Användare"
          style={{ color: "#4d4b51", userSelect: "none" }}
        />
      ),
      icon: <PeopleIcon style={{ fill: "#4d4b51" }} />,
      onClick: () => {
        setOpen(false);
        history.push("/users");
      },
    },
    // {
    //   value: (
    //     <Typography
    //       type="body1"
    //       value="Projekt"
    //       style={{ color: "#0a090a", userSelect: "none" }}
    //     />
    //   ),
    //   icon: <AssignmentIcon style={{ fill: "#4d4b51" }} />,
    //   onClick: () => {
    //     setOpen(false);
    //     history.push("/projects");
    //   },
    // },
  ];

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<any>({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phone: "",
    role: "",
  });

  useEffect(() => {
    setLoading(true);
    getUserData()
      ?.then((userDoc) =>
        userDoc.forEach((userSnap) => {
          const userData = userSnap.data();
          const nameSplit = userData.name.split(" ");
          const firstName = nameSplit.length > 0 ? nameSplit[0] : "";
          const lastName = nameSplit.length > 1 ? nameSplit[1] : "";

          setUser((prevState: any) => {
            setLoading(false);

            return {
              ...prevState,
              ...userData,
              firstName,
              lastName,
            };
          });
        })
      )
      .catch(() => setLoading(false));
  }, []);

  return (
    <FullscreenContainer>
      <Loading isOpen={loading} />
      <div className="PaddingContainer">
        <NavBarContainer
          header="Profil"
          onClickMenu={() => setOpen(true)}
          endContent={
            <Button
              title="Logga ut"
              type="text"
              onClick={() => {
                logoutUser().catch((err) => {
                  console.log(err);
                });
              }}
              style={{
                userSelect: "none",
                border: "none",
                textTransform: "none",
                textDecoration: "none",
                display: "inline-block",
              }}
            />
          }
        />
        <Drawer
          user={user.firstName}
          company={user.company}
          options={options}
          open={open}
          onCloseDrawer={() => setOpen(false)}
          onClickShowProfile={() => {
            setOpen(false);
          }}
        />
        <div className="ProfileScreen-Content">
          <Module header="Konto">
            <div style={{ display: "flex" }}>
              <Inputfield
                placeholder="Förnamn"
                style={{ marginRight: 5 }}
                value={user.firstName}
                disabled={true}
              />
              <Inputfield
                placeholder="Efternamn"
                style={{ marginLeft: 5 }}
                value={user.lastName}
                disabled={true}
              />
            </div>
            <Inputfield
              placeholder="E-mail"
              style={{ marginTop: 16 }}
              value={user.email}
              icon={<EmailIcon style={{ fill: "white" }} />}
              disabled={true}
            />
            <Inputfield
              placeholder="Telefonnummer"
              style={{ marginTop: 16 }}
              value={user.phone}
              icon={<CallIcon style={{ fill: "white" }} />}
              disabled={true}
            />
            <Inputfield
              placeholder="Företag"
              style={{ marginTop: 16 }}
              value={user.company}
              icon={<BusinessCenterIcon style={{ fill: "white" }} />}
              disabled={true}
            />
            <Inputfield
              placeholder="Arbetsroll"
              style={{ marginTop: 16 }}
              value={user.role}
              icon={<AssignmentIndIcon style={{ fill: "white" }} />}
              disabled={true}
            />
          </Module>
        </div>
      </div>
      <Divider />
      <div className="PaddingContainer">
        <Module header="Språk">
          <Select
            placeholder="Språk"
            options={[{ value: 0, label: "Svenska" }]}
            value={0}
            icon={<LanguageIcon style={{ fill: "white" }} />}
          />
        </Module>
      </div>
    </FullscreenContainer>
  );
};

export default ProfileScreen;
